<template>
  <div class="">
    <header-portrate />
    <exports-print ref="exportbar" />
    <div>
    <bread-crumbs :items='items'/>
    <TabsComp :tabs="tabs" />
    <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:-20px !important;`">
      <v-row class="mt-10">
        <v-col
          cols="12"
          md="10"
          sm="12">
          <form autocomplete="off">
          <v-row>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.start_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.day" :options="daysList"/>
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="sd.month" :options="monthsList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="sd.year" :options="yearsList" />
              </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="12">
              <label>{{lang.end_date}}</label>
              <b-input-group>
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
              </b-input-group>
            </v-col>

            <v-col cols="12" md="2" sm="12">
              <label></label>
              <b-input-group style="margin-top:-5px">
                <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getDaysList()">{{lang.search}}</b-button>
              </b-input-group>
            </v-col>
          </v-row>
          
        </form>
        </v-col>
        
      </v-row>
        <div class="myRow">
            <div style="width:10%;padding:2px;">
                <div class="backRed" style="padding:5px;text-align:center;">{{ lang.day_name }}</div>
                <div style="padding:5px;color:#000;background:#eee;text-align:center;">{{ lang.date }}</div>
            </div>
            <div style="width:20%;padding:2px;" v-for="(item,index) in days" :key="index">
                <div class="backBlack" style="padding:5px;text-align:center;">{{ item.name }}</div>
                <div style="padding:5px;color:#fff;background:#222;text-align:center;">{{ item.date }}</div>
                <div style="padding:5px;text-align:center;">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="text-center backBlue">{{ lang.tasks }}</th>
                        <th class="text-center backBlue">{{ lang.supports }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ item.tasksNum }}</td>
                        <td>{{ item.supportNum }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
                <div  style="max-height:600px;overflow:auto;margin-bottom:20px;">
                  <div v-for="(itm,ind) in item.res" :key="ind"> 
                    <div v-for="(itms,inds) in itm" :key="inds" style="border:1px solid #000;margin-bottom:1px;">
                      
                        <div  style="font-size:12px;background:#eee;text-align:center;padding:5px;border:1px solid #FFF" v-if="itms.type == '_tasks_'">
                          <div style="padding:2px;border:1px solid #000;background:blue;color:#fff;font-size:12px;">{{lang.tasks}} - {{ lang.time }} {{ itms.time }}</div>
                          {{ itms.description }}
                          <div :style="`font-size:12px;padding:2px;border:1px solid #000;${itms._status_['style']}`">
                            {{lang.status}}: {{ itms._status_[`title_${lang.langname}`] }}
                          </div>
                          <b-button @click="editTask(itms)" v-if="itms.doc_type != 'customers'" class="backBlue" style="width:100%;padding:2px 10px !important;font-size:.8rem !important;" v-b-toggle.update_task>{{ lang.action }}</b-button>
                          <b-button @click="getCus(itms.docid)" v-else class="backBlue" style="width:100%;padding:2px 10px !important;font-size:.8rem !important;" v-b-toggle.customer_crm>{{ lang.action }}</b-button>
                        </div>
                      <div style="font-size:12px;background:#eee;text-align:center;padding:5px;border:1px solid #FFF" v-if="itms.type == '_support_'">
                        <div style="padding:2px;border:1px solid #000;background:red;color:#fff;font-size:12px;">{{lang.support}} - {{ lang.time }} {{ itms.time }}</div>
                        {{ itms.description }}
                        <div :style="`font-size:12px;padding:5px;border:1px solid #000;${itms._status_['style']}`">
                          {{lang.status}}: {{ itms._status_[`title_${lang.langname}`] }}
                        </div>
                        <b-button @click="editSupport(itms)" class="backBlue" style="width:100%;padding:2px 10px !important;font-size:.8rem !important;" v-b-toggle.update_support>{{ lang.action }}</b-button>
                      </div>
                      <div style="font-size:12px;background:#eee;text-align:center;padding:5px;border:1px solid #FFF" v-if="itms.type == '_calendar_'">
                        <div style="padding:2px;border:1px solid #000;background:red;color:#fff;font-size:12px;">{{lang.calendar}} - {{ lang.time }} {{ itms.time }}</div>
                          {{ itms.description }}
                        <div :style="`font-size:12px;padding:5px;border:1px solid #000;${itms._status_['style']}`">
                          {{lang.status}}: {{ itms._status_[`title_${lang.langname}`] }}
                        </div>
                        <b-button @click="editTask(itms)" class="backBlue" style="width:100%;padding:2px 10px !important;font-size:.8rem !important;" v-b-toggle.update_task>{{ lang.action }}</b-button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </v-row>

    </div>
    <popViewCard ref="viewCard" />
    <UpdateSamTask ref="UpdateSamTask" />
    <UpdateSamSupport ref="UpdateSamSupport" />
    <customerCRM ref="customerRef" />
    <Footer />
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
      </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import BreadCrumbs from '@/components/breadCrumbs.vue'
  import HeaderPortrate from '@/components/Header-Portrate.vue'
  import Footer from '@/components/Footer.vue'
  // import ExportsPrint from '@/components/exports-print.vue'
  import TabsComp from '@/components/tabsComp.vue'
  import {SnotifyPosition} from 'vue-snotify'
  import UpdateSamTask from '@/components/UpdateSamTask.vue';
  import UpdateSamSupport from '@/components/UpdateSamSupport.vue';
  import customerCRM from '@/components/customerCRM.vue'
  export default Vue.extend({
    name: 'Home',

    components: {
        BreadCrumbs,
        HeaderPortrate,
        Footer,
        TabsComp,
        UpdateSamTask,
        UpdateSamSupport,
        customerCRM
    },
    data() {
      return {
        cpage: 0,
        showLoading: false,
        search: '',
        drow: false,
        excel_fields:{},
        ishome: true,
        loading: false,
        sd: {
          day: 1,
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear() - 1,
        },
        ed: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
        days: []
      }
    },
    created() {
        this.getDaysList();
    },
    methods: {
      getCus(id){
          this.$refs.customerRef.customerid = id;
          this.$refs.customerRef.getCustomerInfo();
      },
      editSupport(item) {
          // console.log("task" ,item);
          this.$refs.UpdateSamSupport.crm_id = item.id;
          this.$refs.UpdateSamSupport.task_response = item.userid;
          this.$refs.UpdateSamSupport.status = item.status;
          this.$refs.UpdateSamSupport.notes = '';
          this.$refs.UpdateSamSupport.getTaskDet();
      },
      editTask(item) {
        this.$refs.UpdateSamTask.crm_id = item.id;
        this.$refs.UpdateSamTask.task_response = item.userid;
        this.$refs.UpdateSamTask.status = item.status;
        this.$refs.UpdateSamTask.notes = '';
        this.$refs.UpdateSamTask.getTaskDet();
        this.$refs.UpdateSamTask.show()
            
      },
      getDaysList(){
        const post = new FormData();
        post.append("type", "getDaysList");
        post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
        post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[id]",1)
        axios.post(
            this.$SAMCOTEC.r_path,post
        ).then((response) => {
            this.days = response.data.results.data;
        })
      }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },

        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },

      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },

      tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
  })
</script>
<style>
.myRow{
    margin:auto;
    margin-top:10px;
    display:flex;
    flex-direction:row;
    align-items: stretch;
    justify-content:space-around;
    justify-items:center;
}
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff00;
  z-index: 100000;
}
</style>

